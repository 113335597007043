<template>
  <div style="height:100%">
    <intro v-if="v.intro1" @next="next(1)">서비스 구성이 완료되었습니다.<br>이제 화면 설정이 남았어요!</intro>
    <intro v-if="v.intro2" @next="next(2)">세부적인 디자인 설정 전<br>주요 페이지부터 먼저 골라볼게요 :)</intro>
    <block-builder v-if="v.builder" :service="service" :step="3"></block-builder>
  </div>
</template>

<script>
  import Intro from "./Intro";
  import BlockBuilder from "./Components/BlockBuilder";
  export default {
    name: "Step3",
    components: {BlockBuilder, Intro},
    props: {
      service: {
        type: Object
      }
    },
    created() {
      //this.v.intro1 = true;
      // test
      this.v.builder = true;
    },
    data() {
      return {
        v: {
          intro1: false,
          intro2: false,
          builder: false
        }
      }
    },
    methods: {
      next(num) {
        if(num === 1) {
          this.v.intro1 = false;
          setTimeout(()=>{
            this.v.intro2 = true;
          },200)
        } else {
          this.v.intro2 = false;
          this.v.builder = true;
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>

</style>
